// TODO refactor - use darken and lighten instead of different primary-colors

$color-primary: var(--color-primary);
$color-primary-dark: var(--color-primary-dark);
$color-primary-darker: var(--color-primary-darker);
$color-primary-darkest: var(--color-primary-darkest);
$color-primary-light: var(--color-primary-light);
$color-primary-lighter: var(--color-primary-lighter);
$color-primary-boxshadow: 0 0 0 0.15rem rgba(#b4975a, 0.4);
$color-loginpage-bg-gradient: linear-gradient(to bottom, var(--color-primary) 0, var(--color-primary-darker));
// linear-gradient(to bottom, rgba(var(--color-primary) 0), rgba(var(--color-primary-dark)));

$color-info: #17a2b8;
$color-success: #28a745;
