
body.swal2-shown.swal2-height-auto {
  height: 100% !important;
}

.swal2-container {
  .swal2-close:focus {
    outline: none;
    box-shadow: none;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.swal2-container {
  z-index: 10060 !important;
}
