@import "colors";
// ag-grid
@import "/node_modules/ag-grid-community/styles/ag-grid.css";
@import "/node_modules/ag-grid-community/styles/ag-theme-material.css";
@import "ag-grid-overwrite";
// bootstrap
@import "bootstrap/dist/css/bootstrap.min.css";
@import "bootstrap-overwrite";
// sweetalert
@import "sweetalert2/dist/sweetalert2.min.css";
@import "swal-overwrite";
// primeng
@import "/node_modules/primeng/resources/themes/nova/theme.css";
@import "/node_modules/primeng/resources/primeng.min.css";
@import "primeng-overwrite";
// ngx-toastr
@import "/node_modules/ngx-toastr/toastr.css";
// font-awesome
@import "/node_modules/font-awesome/css/font-awesome.min.css";
// ngx-loading-bar
@import "loading-bar-overwrite";
// quill - html-texteditor in primeng
@import "/node_modules/quill/dist/quill.snow.css";
@import "/node_modules/quill/dist/quill.core.css";

@import "darkmode-overwrite.scss";

bo-root{
  max-width:100vw;
  overflow-y: scroll;
  display:block;
  height:100vh;
}
.pagewrapper {
  min-width: 1480px !important;
}

// links overwrite
body a {
  color: $color-primary;

  &:hover, &:active {
    color: $color-primary-dark;
    text-decoration: none;
  }
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.help-cursor {
  cursor: help;
}

.ng-touched.ng-invalid:not(:disabled) {
  border-color: #FF4A55 !important;
}

@media print {
  .no-print, .toast-container, .swal2-close {
    display: none !important;
  }
}

.border-dashed {
  border-style: dashed !important;
}


.pulse-animation {
  animation: pulse 1s ease-in-out infinite;
}


@keyframes pulse {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}
