/*make cell content (strings) selectable*/
div.ag-cell-value {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  text-overflow: ellipsis;
  overflow: hidden;
}

span.ag-group-expanded, span.ag-group-contracted {
  .ag-icon {
    font-size: 1.6rem;
    padding: 10px;
  }
}

.ag-theme-material .ag-details-row {
  padding: 0;

  .ag-root {

    border: 1px solid #adadad;
  }
}

div.ag-header-cell {
  border-right: 1px solid var(--ag-row-border-color, var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2)));
}

@media (max-width: 600px) {
  .ag-paging-panel {
    display: block;

    .ag-paging-row-summary-panel {
      display: block;
      margin-top: 10px;
    }

    .ag-paging-page-summary-panel {
      margin-top: 15px;
      display: flex;
      float: right;
    }
  }
}

@media (max-width: 400px) {
  .ag-theme-material .ag-paging-button, .ag-theme-material .ag-paging-description {
    margin: 0 3px;
  }
}
