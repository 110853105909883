@import "colors";

$color-bg1: #303030;
$color-bg2: #222;
$color-bg3: #3b3b3c;
$color-formbg: #525252;

body.darkmode {

  ngx-loading-bar .ngx-bar {
    background-color: $color-primary-dark !important;
  }

  .alert.alert-danger {
    background-color: #e20000;
  }

  // scrollbars
  * {
    /* width */
    ::-webkit-scrollbar {
      width: 15px;
      height: 15px;
    }

    ::-webkit-scrollbar-track {
      background: #7a7a7a;
    }

    ::-webkit-scrollbar-thumb {
      transition: all 0.3s ease-in-out;
      background: $color-bg3;
      border-radius: 8px;
      border: 3px solid #7a7a7a;

      &:hover {
        background: $color-bg1;
      }
    }
  }

  // general
  nav h1 a, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, .alert, th, .td-name, td, button.close, .title, ul, li, .card-title, pre, div, .p-inputtext {
    &:not(.text-danger):not(.text-muted) {
      color: white;
    }
  }

  .category:not(a) {
    color: #b0b0b0;;
  }

  .swal2-popup {
    background-color: $color-bg2;
    border: 1px solid #727272;
  }

  .card, .p-card, .list-group-item {
    background: $color-bg1;
  }

  .form-control {
    background-color: $color-formbg !important;
    border: 1px solid #969696;
    color: #ffffff;

    &:disabled {
      background-color: #3a3a3a;
      color: #8d8d8d;
      cursor: not-allowed;
    }
  }

  .input-group {
    input {
      //border-left: none;

      &:focus {
        outline: none;
      }
    }

    .input-group-text {
      color: white;
      border-color: #969696;
      background-color: $color-formbg;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #b3b3b3 inset !important;
  }

  // login
  .full-page {
    h4 {
      img {
        filter: invert(0%);
      }
    }

    &:after {
      background: linear-gradient(to bottom, rgba(13, 42, 55, 0.4), rgba(16, 25, 55, 0.4)) !important;
    }
  }

  // dashboard
  .main-content {
    background: $color-bg3;
  }

  .resolution-tabs .fa {
    background-color: $color-bg2 !important;
  }

  pre {
    background-color: #282828;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid gray;
  }

  .sidebar-wrapper {
    // background: linear-gradient(to bottom, #442e00 0%, #271a00 90%);
    background: linear-gradient(to bottom, rgba(13, 42, 55, 0.4), rgba(16, 25, 55, 0.4)) !important;
  }

  /*.sidebar::before {
    background: linear-gradient(to bottom, #442e00 0%, #271a00 90%);
  }*/

  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    background-color: $color-bg1;
    border: black
  }

  .nav-tabs > li > a:hover {
    border-color: $color-bg1;
  }

  .navbar {
    background-color: $color-bg2;

    .icon-bar {
      background-color: white !important;
    }

    .navbar-brand, .logout {
      color: #dedede !important;
    }

    .de, .en {
      opacity: 1;
    }

    .switch {
      opacity: 0.5;
    }
  }

  .pagewrapper footer {
    border-top: #2f2f2f 1px solid;
    background-color: $color-bg2;
  }


  // bootstrap
  .border, .border-bottom, .border-top {
    border-color: #545454 !important;
  }

  // ngxtoastr
  .toast-container .ngx-toastr {
    box-shadow: 0 0 12px #272727;

    &:hover {
      box-shadow: 0 0 12px #131313;
    }
  }

  // p-datatable

  .p-datatable-table {
    thead th {
      background-color: $color-bg1;
      border-color: $color-bg2;
    }

    tbody tr {
      background-color: $color-bg2;
    }
  }

  // p-datepicker
  .p-datepicker {
    background-color: $color-bg1;

    .p-datepicker-header {
      background-color: $color-bg1;

      .p-datepicker-title button {
        color: white;
      }
    }
  }

  // p-overlay
  .p-overlay .p-overlay-panel {
    background-color: #303030;

  }

  // p-divider
  p-divider .p-divider .p-divider-content {
    background: $color-bg1;
  }

  // p-editor
  .p-editor-container {
    .p-editor-toolbar.ql-snow {
      background-color: $color-bg3;
    }

    .p-editor-content .ql-editor {
      background: #525252;
    }
  }

  // p-confirmPopUp
  .p-confirm-popup {
    background-color: $color-bg1;
    border-color: $color-bg2;

    &::before, &::after {
      border-bottom-color: $color-bg2;
    }
  }

  // p-overlaypanel
  .p-overlaypanel .p-overlaypanel-content {
    background-color: $color-bg1;
  }

  //p-dropdown
  .p-dropdown-panel {
    background-color: $color-bg1;
    color: white;

    input.p-dropdown-filter,
    .p-dropdown-header,
    .p-dropdown-items .p-dropdown-empty-message,
    .p-dropdown-items .p-dropdown-item:not(.p-highlight) {
      background-color: $color-bg1;
      color: white !important;
    }

    .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      background: $color-bg2;
    }

    input.p-dropdown-filter {
      background-color: $color-formbg;
    }
  }

  // p-tree
  p-tree .p-tree {
    background-color: $color-bg1;
    border-color: $color-formbg;

    .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
      background-color: $color-bg2;
      color: white;
    }
  }

  .p-dialog {
    border: 1px solid #727272;

    .p-dialog-content {
      background-color: $color-bg2;
    }
  }

  // tooltip
  .p-tooltip {
    .p-tooltip-text {
      border-color: $color-formbg;
      background-color: $color-bg1;
    }

    &.p-tooltip-right .p-tooltip-arrow {
      border-right-color: $color-formbg;
    }

    &.p-tooltip-left .p-tooltip-arrow {
      border-left-color: $color-formbg;
    }

    &.p-tooltip-top .p-tooltip-arrow {
      border-top-color: $color-formbg;
    }

    &.p-tooltip-bottom .p-tooltip-arrow {
      border-bottom-color: $color-formbg;
    }
  }

  // p-multiselect
  .p-multiselect-panel {
    background-color: $color-bg3;

    .p-multiselect-items {
      .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background-color: $color-bg1;
      }

      .p-multiselect-item.p-highlight:not(.p-disabled) {
        background-color: $color-bg2;
      }

      span {
        color: white;
      }
    }
  }

  //p-calendar
  .p-calendar.p-calendar-w-btn .p-inputtext {
    background-color: $color-formbg;
    border: 1px solid #969696;
    border-right: none;
    outline: none !important;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
    }

    &:hover {
      border: 1px solid #969696 !important;
      border-right: none !important;
    }
  }

  .p-calendar {
    .p-datepicker, .p-datepicker-group, .p-datepicker-header {
      background-color: $color-bg1;
    }
  }

  //p-autocomplete
  .p-autocomplete-items {
    background-color: $color-formbg;
  }

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: white;
    background: $color-bg3;
  }

  //p-accoridion
  .borderless .p-accordion-header .p-accordion-header-link {
    color: white !important;
  }

  .p-accordion-header:not(.p-highlight) {
    &:not(.p-disabled):hover {
      a, span {
        color: white;
      }

      a {
        background-color: #444444 !important;
        border: 1px solid #6b6b6b;
      }
    }

    a {
      box-shadow: none;
      border: 1px solid #6b6b6b;
      background: $color-bg1;
      color: white;
    }
  }

  .p-accordion-content {
    border-color: #484848;
    background: $color-bg1;
  }

  //p-tabview
  .p-tabview-panels {
    background-color: transparent;
    // border: 1px solid rgba(0, 0, 0, 0.18);
  }

  .p-tabview-nav {
    background-color: transparent;

    li {
      &:not(.p-highlight) a {
        color: white !important;
      }

      a {
        background: transparent !important;
      }
    }
  }

  //p-listbox
  .p-listbox-list-wrapper {
    background-color: $color-formbg;

    .p-listbox-list .p-listbox-item {
      color: white;

      &:not(.p-highlight):not(.p-disabled):hover {
        background-color: $color-bg3;
        color: white;
      }
    }
  }


  // ag-grid
  .ag-theme-material, .ag-header-cell, .ag-header-viewport, .ag-row, .ag-row-hover, .ag-center-cols-viewport, .ag-paging-panel {
    background-color: $color-bg1 !important;
  }

  .ag-row-selected, .ag-row-hover {
    background: $color-bg2 !important;

    &:before {
      background-color: transparent !important;
    }
  }

  .ag-theme-material .ag-overlay-loading-center {
    background: $color-bg2;
  }

  .ag-icon {
    color: white !important;
  }

  .ag-menu-list {
    background-color: $color-bg2;

    .ag-menu-option:hover, .ag-menu-option-active, .ag-compact-menu-option-active {
      background-color: $color-bg1;
    }

  }

  *::-webkit-search-cancel-button {
    filter: brightness(100);
    opacity: 0.8;
    cursor: pointer;
  }
}
