@import "colors";

// TODO refactor - use darken() and lighten() instead of different primary-colors

.text-primary {
  color: $color-primary !important;
}

/*.card, .p-tabview-panels, .row {
  max-width: 1900px;
}*/

.btn {
  --bs-btn-border-radius: 0.2rem !important;
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary-dark;

  &:disabled {
    background-color: $color-primary;
    border-color: $color-primary-dark;
  }

  &:not(:disabled) {
    &:hover {
      background-color: $color-primary-dark;
      border-color: $color-primary-darker;
    }

    &:active {
      background-color: $color-primary-darker !important;
      border-color: $color-primary-dark !important;
      box-shadow: $color-primary-boxshadow !important;
    }

    &:focus {
      background-color: $color-primary-dark;
      border-color: $color-primary-darker;
      box-shadow: $color-primary-boxshadow;
    }
  }
}

.btn-outline-primary {
  border-color: $color-primary;
  color: $color-primary;
  outline: none;

  &:not(:disabled) {
    &:hover, &:focus {
      background-color: $color-primary;
      border-color: $color-primary-dark;
    }

    &:focus {
      color: white;
    }

    &:active {
      background-color: $color-primary-dark;
      border-color: $color-primary-darker;
      box-shadow: $color-primary-boxshadow;
      color: white;
    }
  }
}


.text-info {
  color: $color-info !important;
}

.btn-outline-info {
  color: $color-info;
  border-color: $color-info;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(:disabled) {
    &:hover {
      color: white;
      background-color: $color-info;
      border-color: darken($color-info, 3%);
    }

    &:active {
      color: white;
      background-color: lighten($color-info, 1%) !important;
      border-color: $color-info !important;
    }

    &:focus {
      color: white;
      background-color: $color-info;
      border-color: darken($color-info, 3%);

    }
  }
}

.btn-info {
  color: white !important;
  background-color: $color-info;
  border-color: darken($color-info, 3%);

  &:disabled {
    background-color: $color-info;
    border-color: darken($color-info, 3%);
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(:disabled) {
    &:hover {
      background-color: darken($color-info, 3%);
      border-color: darken($color-info, 5%);
    }

    &:active {
      background-color: lighten($color-info, 1%) !important;
      border-color: $color-info !important;
    }

    &:focus {
      background-color: $color-info;
      border-color: darken($color-info, 3%);

    }
  }
}

.bg-info {
  background-color: $color-info;
}


.text-success {
  color: $color-success !important;
}

.btn-outline-success {
  color: $color-success;
  border-color: $color-success;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(:disabled) {
    &:hover {
      color: white;
      background-color: $color-success;
      border-color: darken($color-success, 3%);
    }

    &:active {
      color: white;
      background-color: lighten($color-success, 1%) !important;
      border-color: $color-success !important;
    }

    &:focus {
      color: white;
      background-color: $color-success;
      border-color: darken($color-success, 3%);

    }
  }
}

.btn-success {
  color: white !important;
  background-color: $color-success;
  border-color: darken($color-success, 3%);

  &:disabled {
    background-color: $color-success;
    border-color: darken($color-success, 3%);
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:not(:disabled) {
    &:hover {
      background-color: darken($color-success, 3%);
      border-color: darken($color-success, 5%);
    }

    &:active {
      background-color: lighten($color-success, 1%) !important;
      border-color: $color-success !important;
    }

    &:focus {
      background-color: $color-success;
      border-color: darken($color-success, 3%);

    }
  }
}

.bg-success {
  background-color: $color-success;
}


.table {
  tr {
    border-top: none;
  }

  td, .table th {
    border-top: 1px solid #dee2e6;
    padding: .75rem;
    border-bottom: none;
  }
}

.table > :not(:first-child) {
  border-top: none;
}

ul {
  margin-bottom: 0;
}


